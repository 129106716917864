import React from 'react'

const Contact = () => {
  return (
    <section className='container contact-section mt-5' id='Contact'>
      <div className='section-header  text-center'>
        <div className='section-title'>Contact</div>
        <p className='section-tagline'>WE'D LOVE TO HEAR FROM YOU!</p>
      </div>
      <div className="section-contact">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id Sextilius factum negabat. Quo tandem modo? At eum nihili facit; Quae contraria sunt his, malane?</p>
      </div>
      <div className='contact text-center'>
        <a href="mailto:@sineworknegasi38@gmail.com" className='Aboutbtn'>
          Get in touch
        </a>
      </div>
    </section>
  )
}

export default Contact