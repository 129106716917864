import React from 'react'

const Services = () => {
  return (
    <section id='Service'>
      <div class="container">
        <div className="section-header text-center">
          <h2 className='section-title text-center'>Services</h2>
          <p className='section-tagline text-center'>We work with you</p>
        </div>
        <div className='section-body text-center rows'>
          <div className='col col-md service-list w-100 '>Digital & Concept</div>
          <div className='col col-md service-list w-100 '>Digital Strategy</div>
          <div className='col col-md service-list w-100 '>Content Strategy</div>
        </div>
        <div className="section-body rows text-center pb-5 mb-5">
          <div className='col service-list w-100 '>UX Design</div>
          <div className='col service-list w-100 '>Web Development</div>
          <div className='col service-list w-100 '>Social Media</div>
        </div>
      </div>
    </section>
  )
}

export default Services