import React from 'react'

const Hero = () => {
  return (
    <section className='bg-black'>
      <div className="container hero">
        <h2>We Help you build your <br />brand</h2>
        <button className='buttons'>Get Started </button>
      </div>
    </section>
  )
}

export default Hero