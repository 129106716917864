import React from 'react'
import person1 from '../../images/pic-person-01.jpg'
import person2 from '../../images/pic-person-02.jpg'
import person3 from '../../images/pic-person-03.jpg'

const Testimonial = () => {
  return (
    <section className='testimonial-section text-center container' id='Testimonial'>
      <div className="section-title mt-5">Testimonial</div>
      <div className="section-tagline">We are more than A digital Company</div>
      <div className="row pt-5 ">
        <div className="col-md-4 col-sm-4 col-xs-12">
          <img className='profile' src={person1} alt="" />
          <blockquote className='card-quote'>
            <p className='text-center'>I am completely blown away <br />Thanks to Techium, we've just <br />lauched our 5th website</p>
            <cite>Yuri Y.</cite>
          </blockquote>
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12">
          <img className='profile' src={person2} alt="" />
          <blockquote className='card-quote'>
            <p>Thank you so much for your <br /> help. Techium company is <br /> awesome!</p>
            <cite>Dorriw S.</cite>
          </blockquote>
        </div>
        <div className="col">
          <img className='profile' src={person3} alt="" />
          <blockquote className='card-quote'>
            <p>I love your system. Definitely <br /> worth the investment. I'd be <br /> lost without Techium <br /> company.</p>
            <cite>Sven H.</cite>
          </blockquote>
        </div>
      </div>
    </section>
  )
}

export default Testimonial