import React from 'react'
import logo from '../../images/logo-white.png'
import {BsFacebook, BsTwitter, BsInstagram} from 'react-icons/bs'

const Footer = () => {
  return (
    <section className='bg-black'>
      <div className="container pt-5">
        <div>
          <div className="footer">
            <div>
              <img className='pt-5' src={logo} alt="" />
              <address>972 Mission St <br /> San Francisco,CA <br /> 94103</address>
            </div>
            <ul className='Social-media'>
              <li><a href=""><BsFacebook></BsFacebook></a></li>
              <li><a href=""><BsTwitter></BsTwitter></a></li>
              <li><a href=""><BsInstagram></BsInstagram></a></li>
            </ul>
          </div>
          <hr/>
        </div>
        <div className="footer py-5">
          <div className='text-white'>© {new Date().getFullYear()} Techium, made with ♥ by students at Holberton School.</div>
          <div className='justify-content-sm-between fl'>
            <a className='mx-2 footer-links' href="">Terms of use </a>
            < a className='mx-2 footer-links' href="">Privacy policy</a>
            <a className='mx-2 footer-links' href=''>Cookie policy</a>
            </div>
        </div>
      </div>
    </section>
  )
}

export default Footer