import React from 'react'
import Logo from '../../images/logo-white.png'
import {FaBars} from 'react-icons/fa'
import 'bootstrap/dist/css/bootstrap.css'

const Header = () => {
  return (
    <section className='bg-black flex-fixed'>
    <header className='container pt-4'>
      <img src={Logo} alt="" />
      <button onClick={() => {
        let navBar = document.querySelector(".nav-bar");
        navBar.classList.toggle("active");
      }} className="hamburger">
        <FaBars className='lines'/>
      </button>
      <nav className="nav-bar">
            <ul className='row text-center'>
              <li className="col links m-2">
              <a href="#Home">Home</a>
              </li>
              <li className="col links m-2">
              <a href="#Service">Service</a>
              </li>
              <li className="col links m-2">
              <a href="#Work"> Works</a>
                </li>
              <li className="col links m-2">
              <a href="#About">About</a>
                </li>
              <li className="col links m-2">
              <a href="#Blog">Blog</a>
                </li>
              <li className="col links m-2">
              <a href="#Testimonial">Testimonial</a>
                </li>
              <li className="col links m-2">
              <a href="#Contact">Contact</a>
                </li>
            </ul>
      </nav>
    </header>
    </section>
  )
}

export default Header