import React from 'react'
import work1 from '../../images/pic-work-01.jpg'
import work2 from '../../images/pic-work-02.jpg'
import work3 from '../../images/pic-work-03.jpg'
const Works = () => {
  return (
    <section className='bg-black' id='Work'>
      <div className="container py-5 mt-5">
        <div className='section-header text-center'>
          <h2 className='section-title text-white'>Works</h2>
          <p className="section-tagline">Take a look At our Portofolio</p>
        </div>
        <div className="section-body container-fluid">
          <ul className='row align-items-center'>
            <li className=' col-sm-4 col-xs-12'>
              <div className="images">
                <img className='workimg' src={work1} alt="" />
                <div class="overlay">
                  <div class="text">Interior Design</div>
                </div>
              </div>
            </li>
            <li className='col-sm-4 col-xs-12'>
              <div className="images">
              <img className='workimg' src={work2} alt="" />
                <div class="overlay">
                  <div class="text">Web Development</div>
                </div>
              </div>
            </li>
            <li className='col-sm-4 col-xs-12'>
              <div className="images">
              <img className='workimg' src={work3} alt="" />
                <div class="overlay">
                  <div class="text">Personal Development</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Works