import React from 'react'
import about from '../../images/pic-about-01.jpg';

const About = () => {
  return (
    <section className='Section-about mt-5' id='About'>
      <div className="container">
        <div className='Section-header text-center'>
          <div className="section-title">About Us</div>
          <div className="section-tagline">Everything About US</div>
        </div>
        <div className="Section-body row pt-5 align-items-center">
          <div className="col-md-6 col-sm-4 col-xs-12">
            <img className='Aboutimg' src={about} alt="" />
          </div>
          <div className="col-md-6 col-sm-4 col-xs-12">
            <h4 className='my-3'>Who are we</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum, omnis expedita! Eum, praesentium cumque accusantium rem, sit quaerat est nisi ratione, deserunt ducimus quidem iste dicta quibusdam atque maxime cum!</p>
            <h4 className='my-3'>Our Culture</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum, omnis expedita! Eum, praesentium cumque accusantium rem, sit quaerat est nisi ratione, deserunt ducimus quidem iste dicta quibusdam atque maxime cum!</p>
            <h4 className='my-3'>How we work</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum, omnis expedita! Eum, praesentium cumque accusantium rem, sit quaerat est nisi ratione, deserunt ducimus quidem iste dicta quibusdam atque maxime cum!</p>
          </div>
          <div className='align-items-center text-center my-5 py-5'>
            <a className="Aboutbtn">Learn more about us</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About