import React from 'react'
import news1 from '../../images/pic-article-01.jpg'
import news2 from '../../images/pic-article-02.jpg'
import news3 from '../../images/pic-article-03.jpg'

const Blogs = () => {
  return (
    <section className='container Blog-section mt-5' id='Blog'>
      <div className="section-title text-center p-5">Latest News</div>
      <div className="row">
        <div className="col-md-4 col-sm-4 col-xs-12 mb-5">
          <img className='Blogimg' src={news1} alt="" />
          <h5 className='p-2 card-catogories'>Career</h5>
          <a href='#d' className='my-3 card-t'>Hoc loco tenere se Triarius non potuit.</a>
          <p className='card-p'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id Sextilius factum negabat. Quo tandem modo? At eum nihili facit; Quae contraria sunt his, malane?</p>
          <p>By Kelly D.</p>
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12">
        <img className='Blogimg' src={news2} alt="" />
          <h5 className='p-2 card-catogories'>Digital Life</h5>
          <a href='#s' className='my-3 card-t'>Ut alios omittam, hunc appello, quem ille unum secutus est.</a>
          <p className='card-p'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id Sextilius factum negabat. Quo tandem modo? At eum nihili facit; Quae contraria sunt his, malane?</p>
          <p>By William A.</p>
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12">
        <img className='Blogimg' src={news3} alt="" />
          <h5 className='p-2 card-catogories'>Social</h5>
          <a href='#s' className='my-3 card-t'>Bestiarum vero nullum iudicium puto.</a>
          <p className='card-p'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id Sextilius factum negabat. Quo tandem modo? At eum nihili facit; Quae contraria sunt his, malane?</p>
          <p>By Frances J.</p>
        </div>
      </div>
    </section>
  )
}

export default Blogs