import "./App.css";
import 'bootstrap/dist/css/bootstrap.css'
import About from "./components/AboutUs/About";
import Blogs from "./components/Blogs/Blogs";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import Services from "./components/Services/Services";
import Testimonial from "./components/Testimonial/Testimonial";
import Works from "./components/Works/Works";

function App() {
  return (
    <>
      <Header></Header>
      <Hero></Hero>
      <Services></Services>
      <Works></Works>
      <About></About>
      <Blogs></Blogs>
      <Testimonial></Testimonial>
      <Contact></Contact>
      <Footer></Footer>
    </>
  );
}

export default App;
